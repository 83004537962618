import React from 'react'
import { EGAnalytics, analyticEvents } from './analytics'

export const mapLinks = group => {
  return group.map(item => {
    return {
      label: item.label,
      url: item.url
    }
  })
}

export const renderLinks = (group, groupName, opts = {}) => {
  const {
    trackAsMenuInteraction = true,
    className = ''
  } = opts

  const handleLinkClick = item => {
    if (trackAsMenuInteraction) {
      EGAnalytics.track(analyticEvents.NAVIGATION_MENU_INTERACTION, {
        navSectionName: groupName,
        navItemName: item.label,
        navItemUrl: item.url
      })
    }
  }

  return group.map((item, i) => {
    return () => (
      <a
        href={ `${item.url}` }
        className={ className }
        key={ `link-${i}` }
        onClick={ () => handleLinkClick(item) }
      >
        { item.label }
      </a>
    )
  })
}
