import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import _ from 'lodash'

const SEO = (props) => {
  const {
    lang = 'en',
    meta = [],
    keywords = [],
    // Contentful page id of parent to get specific metadata
    pageContentfulId
  } = props

  const renderSEO = (data) => {
    const homePageMetaData = _.get(data, 'allContentfulHomeV3.edges').find(c =>{
      //if contentPageId available take contentful data, otherwise take the home page meta data
      const contentfulId = pageContentfulId || process.env.GATSBY_CONTENTFUL_ID_HOME
      return c.node.contentful_id === contentfulId
    })
    const siteMetadata = _.get(homePageMetaData, 'node.metadata', {})
    const title = _.get(meta, 'title') || props.title
    const pageTitleSuffix = _.get(meta, 'pageTitleSuffix')  || siteMetadata.pageTitleSuffix
    return (
      <Helmet
        htmlAttributes={{ lang }}
        title={ title }
        titleTemplate={`%s ${pageTitleSuffix}`}
        meta={
          [
            { name: 'description', content: siteMetadata.description },
            { name: 'twitter:creator', content: siteMetadata.author },
            { name: 'twitter:card', content: 'summary' },
            { property: 'og:title', content: title },
            { property: 'og:description', content: siteMetadata.description },
            { property: 'og:type', content: 'website' }
          ].concat(meta).concat(keywords.length ? keywords : [])
        }
        script={[{
          type: 'text/javascript',
          innerHTML: `!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
          posthog.init('a-GP92Aj_umxtZNi--BtzhetDMlzzVkWGzlZwvY8Z-8',{api_host:'https://app.posthog.com'})`
        }]}
      />
    )
  }

  return (
    <StaticQuery
      query={
        graphql`
          query Metadata {
            allContentfulHomeV3 {
              edges {
                node {
                  contentful_id
                  metadata {
                    author
                    description
                    keywords
                    pageTitleSuffix
                  }
                }
              }
            }
          }
        `
      }
      render={ renderSEO }
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.array,
  pageContentfulId: PropTypes.string,
}

export default SEO
