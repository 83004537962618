import _ from 'lodash'

export const openIntercom = (event) => {
  event && event.preventDefault()
  if(window.Intercom) {
    window.Intercom('show')
  }
}

export const toCurrency = value => {
  return Number(value).toLocaleString('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
}

export const formattedNumber = value => {
  return Number(value).toLocaleString()
}

/**
 * Gets home SEO/header data for based on the contentfulID
 * @param {*} pageInfo pageInfos of all the structure which are based on homeV3 structure of Conentful.
 * @param {*} contentfulId page specific contentful Id(i.e home page id, voice page id)
 * @returns Object which contains page title and page metdata
 */
export const getCmsContentType = (pageInfo, contentfulId) => {
  const contentType = _.get(pageInfo, 'data.allContentfulHomeV3.edges', []).find(c => {
    return _.get(c, 'node.contentful_id') === contentfulId
  })
  return _.get(contentType, 'node')
}

export const scrollToElement = (element, offset = 0) => {
  const elementPosition = element.offsetTop
  const offsetPosition = elementPosition - offset
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

/**
 * A generic function to put gtag in the different places in the app.
 * @param {*} sendId Google tag sendId where the event needs to be sent.
 * @param {*} url
 * @returns false
 */
export function gtagReportConversion(sendId, url) {
  // Tracking of gtag only in production.
  if(process.env.GATSBY_APP_ENV === 'prod') {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url
      }
    }
    // eslint-disable-next-line
    gtag('event', 'conversion', {
      'send_to': sendId,
      'event_callback': callback
    })
    return false
  }
}
