import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'

import { EGAnalytics, analyticEvents } from '../../services/analytics'
import { scrollToElement } from '../../services/util'
import { renderLinks } from '../../services/links'
import { Button } from '../FormComponents'
import { types as buttonTypes } from '../FormComponents/Button'
import Menu from '../Menu'
import './styles.scss'

const Header = props => {
  const { data, className = '' } = props
  const [scroll, setScroll] = useState(false)
  const { contentfulHeader: headerData } = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulHeader {
        company {
          label
          url
        }
        cta
        logo {
          file {
            fileName
            url
            contentType
          }
        }
        partners {
          label
          url
        }
        products {
          label
          url
        }
      }
    }
  `)

  useEffect(() => {
    const scrollCallback =  window.addEventListener('scroll', () => {
      if (window.pageYOffset > 20) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    })

    return () => { window.removeEventListener('scroll', scrollCallback) }
  }, [])

  const handleGetDemoClick = () => {
    EGAnalytics.track(analyticEvents.CTA_INTERACTION, {
      page: window.location.pathname,
      section: 'Header',
      ctaLabel: data.cta
    })
    const toScrollElement = document.querySelector('#eg-demo-section')
    toScrollElement && scrollToElement(toScrollElement, 88)
  }

  const handleGetDemoClickMobile = () => {
    document.getElementById('mobile-menu-switcher').checked = false
    handleGetDemoClick()
  }

  const menuProductsItems = renderLinks(headerData.products, 'Products')
  const partnersProductsItems = renderLinks(headerData.partners, 'Partners')
  const menuCompanyItems = renderLinks(headerData.company, 'Company')

  return (
    <div className={`eg-header ${scroll ? '-scroll' : ''}`}>
      <div className={`inner ${className}`}>
        <div className='brand' onClick={() => navigate('/')}>
          <div className='logo'>
            <img src={ headerData.logo.file.url } alt='zsf-logo' />
          </div>
        </div>

        <div className='right -desktop'>
          <Menu
            label='PRODUCTS'
            items={ menuProductsItems }
            className='menu'
          />
          <Menu
            label='PARTNERS'
            items={ partnersProductsItems }
            className='menu'
          />
          <Menu
            label='COMPANY'
            items={ menuCompanyItems }
            className='menu'
          />
          <Button
            type={ buttonTypes.SECONDARY }
            text={ headerData.cta }
            className='cta'
            onClick={ handleGetDemoClick }
          />
        </div>
        <div className='right -mobile' tabIndex='0'>
          <input type='checkbox' id='mobile-menu-switcher' />
          <label className='hamburger' id='hamburger' htmlFor='mobile-menu-switcher'>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </label>
          <div className='mobile-menu' tabIndex='0'>
            <section className='products'>
              <div className='title'>Products</div>
              <div className='items'>
                {
                  menuProductsItems.map((item, index) => {
                    return <div className='item' key={ `product-${index}` }>{ item() }</div>
                  })
                }
              </div>
              <div className='title'>Partners</div>
              <div className='items'>
                {
                  partnersProductsItems.map((item, index) => {
                    return <div className='item' key={ `partner-${index}` }>{ item() }</div>
                  })
                }
              </div>
              <div className='title'>Company</div>
              <div className='items'>
                {
                  menuCompanyItems.map((item, index) => {
                    return (
                      <div className='item' key={ `company-${index}` }>{ item() }</div>
                    )
                  })
                }
              </div>
            </section>
            <div className='action'>
              <Button
                type={ buttonTypes.SECONDARY }
                text={ headerData.cta }
                className='cta'
                onClick={ handleGetDemoClickMobile }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
}

export default Header
