import React from 'react'
import ReactSlider from 'react-input-slider'
import PropTypes from 'prop-types'

import './styles.scss'

const styles = {
  track: {
    backgroundColor: '#e1e5e9',
    width: '100%'
  },
  active: {
    backgroundColor: '#2bbfb3'
  }
}

const Slider = ({
  axis = 'x',
  step = 1,
  min = 0,
  max,
  value,
  className = '',
  onChange
}) => {

  const handleChange = (value) => {
    if (onChange) {
      onChange(value.x)
    }
  }

  return (
    <div className={ `eg-slider ${className}` }>
      <ReactSlider
        axis={ axis }
        xstep={ step }
        xmin={ min }
        xmax={ max }
        x={ value }
        styles={ styles }
        onChange={ handleChange }
      />
    </div>
  )
}

Slider.propTypes = {
  axis: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func
}

export default Slider
