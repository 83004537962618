import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { globalHistory } from '@reach/router'
import Loadable from '@loadable/component'
import _ from 'lodash'
import 'lazysizes'

import '../../sass/main.scss'
import { EGAnalytics, analyticEvents } from '../../services/analytics'
import SEO from '../SEO'
import ErrorBoundary from '../ErrorBoundary'
import Header from '../Header'
const Footer = Loadable(() => import(/* webpackPrefetch: true */ '../Footer'))


const MainLayout = props => {
  const data = props.data
  const { headerClass = '' }  = props

  useEffect(() => {
    EGAnalytics.track(analyticEvents.PAGE_VIEWED, {
      segmentPageName_: 'Page Viewed'
    })
    window.scrollTo(0, 0)
  }, [])

  const setIntercomChatEvents = useCallback(() => {
    if(!window.intercomEventsTracked){
      if (window.Intercom && window.analytics) {
        // track 'Intercom Chat Initiated'
        window.Intercom('onShow', () => {
          const { location } = globalHistory
          EGAnalytics.track(analyticEvents.INTERCOM_CHAT_INITIATED, {
            page: location.pathname
          })
        })

        // track 'Intercom Chat Abandoned'
        window.Intercom('onHide', () => {
          const { location } = globalHistory
          EGAnalytics.track(analyticEvents.INTERCOM_CHAT_ABANDONED, {
            page: location.pathname
          })
        })
        window.intercomEventsTracked = true
        window.intervalTimer && clearInterval(window.intervalTimer)
      }
    }
  }, [])

  useEffect(() => {
    window.intervalTimer = setInterval(setIntercomChatEvents, 500)
  }, [setIntercomChatEvents])

  const meta = _.get(data, 'metadata', [])
  return (
    <ErrorBoundary>
      <div className='eg-zsf-landing'>
        <SEO
          title={ data.title }
          pageContentfulId= {_.get(data, 'contentful_id')}
          meta={meta}
        />
        <Header data={ data.hero } className={headerClass} />
        { props.children }
        <Footer />
      </div>
    </ErrorBoundary>
  )
}

MainLayout.propTypes = {
  headerClass: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.any
}

export default MainLayout
