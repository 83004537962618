export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const PATHS = {
  PRESS: '/press'
}

export const REGEXES = {
  EMAIL: /^[^\s@]+@[^\s@]+$/,
  PHONE_NUMBER: /^((\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})?$/
}


export const GTAG_SEND_IDS = {
  EMAIL_SUBMISSION_SEND_ID: 'AW-448731420/sFtlCMzl3_EBEJyy_NUB',
  // LEAD_GEN_SUBMISSION_SEND_ID: 'AW-448731420/xCKYCIeWwdYCEJyy_NUB',
  LEAD_GEN_SUBMISSION_SEND_ID: 'AW-448731420/jIhCCLXhtf0CEJyy_NUB',
  CALENDLY_EVENT_SEND_ID: 'AW-448731420/clY6CLHC69YCEJyy_NUB'
}


export const ZSF_GOOGLE_PIXEL_IDS = {
  EMAIL_SUBMISSION_SEND_ID: 'AW-605010127/3YrgCKf2yvYCEM_xvqAC',
  // LEAD_GEN_SUBMISSION_SEND_ID: 'AW-605010127/XnURCNDD9PYCEM_xvqAC',
  LEAD_GEN_SUBMISSION_SEND_ID: 'AW-605010127/2U1GCKLTtoQDEM_xvqAC',
  CALENDLY_EVENT_SEND_ID: 'AW-605010127/NW9BCKvF9PYCEM_xvqAC'
}
