import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Input = React.forwardRef((props, ref) => {
  const {
    name,
    value = '',
    placeholder = '',
    type = 'text',
    className = '',
    required = false,
    label = '',
    errorMessage,
    ...rest
  } = props

  const handleChange = e => {
    if (props.onChange) {
      props.onChange(e)
    }
  }

  return (
    <div className={ `eg-input ${className} ${errorMessage ? 'error-border': ''}` }>
      <label htmlFor={name}>{label}</label>
      <input
        name={name}
        className='input'
        type={ type }
        value={ value }
        placeholder={ placeholder }
        onChange={ handleChange }
        required={required}
        ref={ ref }
        {...rest}
      />
      {errorMessage && <div className='error'>{errorMessage}</div>}
    </div>
  )
})

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default Input
