import React from 'react'
import PropTypes from 'prop-types'

import useKeyboardNavigation from '../../services/a11y'
import './styles.scss'

const Menu = props => {
  const { label, items, className = '' } = props
  useKeyboardNavigation()

  return (
    <div className={ `eg-menu ${className}` }>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <div className='trigger'>
        {
          label && (
            <span className='label'>{ label }<span className='caret'></span></span>
          )
        }
        <span className='spacer'></span>
        <div className='items'>
          {
            items.map((item, i) => (
              <div className='item' key={ i } onClick={ item.onClick }>
                { item() }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  className: PropTypes.string
}

export default Menu
