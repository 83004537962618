// Generated by Avo VERSION 85.3.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

(function(exports) {
  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
  const avoInspectorApiKey = "VbIOF6DT0ApXlHuFidIz";
  var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

  var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

  var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "P1OjjD1lfZoy3Osqv2Zc",
    "br": "master",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "ZMwnebQikC6pCSNEAgJH",
    "se": (new Date()).toISOString(),
    "so": "7P0sSvvtZ",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "P1OjjD1lfZoy3Osqv2Zc",
    "br": "master",
    "en": env,
    "ty": type,
    "sc": "ZMwnebQikC6pCSNEAgJH",
    "se": (new Date()).toISOString(),
    "so": "7P0sSvvtZ",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


  var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "ZMwnebQikC6pCSNEAgJH",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}


var SegmentZeroStorefrontLanding = {
  make: function make(apiKey, options) {
    if (typeof window === 'undefined') { InternalAvoLogger.warn('window.analytics is not available in Node.js'); return; }
    var analytics = window.analytics = window.analytics || [];
    if (analytics.initialize) return;
    if (analytics.invoked) {
      if (InternalAvoLogger && InternalAvoLogger.error) {
        InternalAvoLogger.error('Segment snippet included twice.');
      }
      return;
    }
    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware'
    ];
    analytics.factory = function(method){
      return function(){
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function(key, options){
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/'
        + key + '/analytics.min.js';
      var first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };
    analytics._writeKey = apiKey;
    analytics.SNIPPET_VERSION = '4.13.2';

    analytics.load(apiKey, options);
  },

  /* Segment does not have revenue api, @see https://segment.com/docs/spec/track/#properties */
  logEvent: function logEvent(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.track(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },

  setUserProperties: function setUserProperties(userProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userProperties, Object.assign({}, {integrations: integrations}));
  },

  identify: function identify(userId, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userId, {}, Object.assign({}, {integrations: integrations}));
  },

  unidentify: function unidentify(integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(null, {}, Object.assign({}, {integrations: integrations}));
  },

  page: function page(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.page(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },
};

  var ContactMethod = {
    CALL: "Call",
    MESSAGE: "Message",
    INTERCOM: "Intercom",
    LETS_TALK: "Let's talk",
    EMAIL: "Email",
  };

  function assertCustomerVisitCount(customerVisitCount, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("0B4bqaXJLE", label_ ? "customer_visit_count" + ": " + label_ : "customer_visit_count", customerVisitCount));
    return messages;
  }

  function assertCustomerListCount(customerListCount, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("0MvJ39b8tR", label_ ? "customer_list_count" + ": " + label_ : "customer_list_count", customerListCount));
    return messages;
  }

  function assertFollowers(followers, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("AqAMgc6zef", label_ ? "followers" + ": " + label_ : "followers", followers));
    return messages;
  }

  function assertEmailListSize(emailListSize, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("GilQbaWJIt", label_ ? "email_list_size" + ": " + label_ : "email_list_size", emailListSize));
    return messages;
  }

  function assertRevenueAmount(revenueAmount, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertFloat("ITCVfkSTke", label_ ? "revenue_amount" + ": " + label_ : "revenue_amount", revenueAmount));
    return messages;
  }

  function assertPage(page, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("N6aDd07Es", label_ ? "page" + ": " + label_ : "page", page));
    return messages;
  }

  function assertOptionalSection(section, label_) {
    var messages = [];
    if (section !== undefined && section !== null) {
      messages = messages.concat(AvoAssert.assertString("TApVZrNlc", label_ ? "section" + ": " + label_ : "section", section));
    }
    return messages;
  }

  function assertCtaLabel(ctaLabel, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("U3HfUq-KRk", label_ ? "cta_label" + ": " + label_ : "cta_label", ctaLabel));
    return messages;
  }

  function assertOptionalAvgOrderValue(avgOrderValue, label_) {
    var messages = [];
    if (avgOrderValue !== undefined && avgOrderValue !== null) {
      messages = messages.concat(AvoAssert.assertFloat("VKVSDe6-gI", label_ ? "avg_order_value" + ": " + label_ : "avg_order_value", avgOrderValue));
    }
    return messages;
  }

  function assertOptionalApplicationName(applicationName, label_) {
    var messages = [];
    if (applicationName !== undefined && applicationName !== null) {
      messages = messages.concat(AvoAssert.assertString("Xu46AHz9Z5", label_ ? "application_name" + ": " + label_ : "application_name", applicationName));
    }
    return messages;
  }

  function assertNavSectionName(navSectionName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("_5lOiKrRRT", label_ ? "nav_section_name" + ": " + label_ : "nav_section_name", navSectionName));
    return messages;
  }

  function assertSegmentPageName_(segmentPageName_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-page-segment-name", label_ ? "Segment: Page Name" + ": " + label_ : "Segment: Page Name", segmentPageName_));
    return messages;
  }

  function assertUserId_(userId_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-user-id", label_ ? "User Id" + ": " + label_ : "User Id", userId_));
    return messages;
  }

  function assertOptionalOrderCount(orderCount, label_) {
    var messages = [];
    if (orderCount !== undefined && orderCount !== null) {
      messages = messages.concat(AvoAssert.assertInt("ciO9jwO5We", label_ ? "order_count" + ": " + label_ : "order_count", orderCount));
    }
    return messages;
  }

  function assertNavItemUrl(navItemUrl, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("gRcqidYwzj", label_ ? "nav_item_url" + ": " + label_ : "nav_item_url", navItemUrl));
    return messages;
  }

  function assertMonthlyVisit(monthlyVisit, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("pHKmcYYhSw", label_ ? "monthly_visit" + ": " + label_ : "monthly_visit", monthlyVisit));
    return messages;
  }

  function assertContactMethod(contactMethod, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("uU28EvNtO", label_ ? "contact_method" + ": " + label_ : "contact_method", contactMethod));
    if ("Call" !== contactMethod && "Message" !== contactMethod &&
          "Intercom" !== contactMethod && "Let's talk" !== contactMethod &&
          "Email" !== contactMethod) {
      var message = (label_ ? "contact_method" + ": " + label_ : "contact_method") + " should match one of the following values [ Call | Message | Intercom | Let's talk | Email ] but you provided the value " + contactMethod
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "uU28EvNtO", message: message}]);
    }
    return messages;
  }

  function assertNavItemName(navItemName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("xWRKGkqCu", label_ ? "nav_item_name" + ": " + label_ : "nav_item_name", navItemName));
    return messages;
  }

  function setAvoLogger(avoLogger) {
    __AVO_LOGGER__ = avoLogger;
  }

  function setup_(options, systemProperties, destinationOptions) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
      if (options.useProductionKey) {
        SegmentZeroStorefrontLanding.make(
          "U7NE1urDBrYEfW7wYRRHxtzCVmZMe9pa",
          destinationOptions.segmentZerostorefrontLanding
        );
      } else {
        SegmentZeroStorefrontLanding.make(
          "FyChHlUYNuoQV3B3SjvSiMk8z1WtIDwQ",
          destinationOptions.segmentZerostorefrontLanding
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
      }
    }
  }

  function initAvo(options, systemProperties, destinationOptions) {
    if (__AVO_ENV__ !== null) {
      return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.avoLogger !== undefined) {
      __AVO_LOGGER__ = options.avoLogger;
    }
    if (options.noop === true) {
      __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
      InternalAvoLogger.warn("****************************************************");
      InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
      InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
      InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
      InternalAvoLogger.warn("****************************************************");
      __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
      InternalAvoLogger.log("****************************************************");
      InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
      InternalAvoLogger.log("- No events will be sent");
      InternalAvoLogger.log("- No network requests are made");
      InternalAvoLogger.log("****************************************************");
    }
    if (options.strict !== undefined) {
      __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
      __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (!__AVO_NOOP__ && options.inspector !== undefined) {
      __INSPECTOR__ = options.inspector;
    } else if (__AVO_ENV__ !== 'prod') {
      InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
    }
    if (__AVO_ENV__ !== 'prod') {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

    }
    if (!__AVO_NOOP__) {
      if (__AVO_ENV__ === 'prod') {
        SegmentZeroStorefrontLanding.make(
          "U7NE1urDBrYEfW7wYRRHxtzCVmZMe9pa",
          destinationOptions.segmentZerostorefrontLanding
        );
      }
      if (__AVO_ENV__ === 'dev') {
        SegmentZeroStorefrontLanding.make(
          "FyChHlUYNuoQV3B3SjvSiMk8z1WtIDwQ",
          destinationOptions.segmentZerostorefrontLanding
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
      }
    }
  }

  /**
   * Contact Us Interaction: Event sent when a user clicks one of the contact us methods
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   * @param {string} properties.contactMethod - The method to contact Eatgeek associated with this event
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/xSYODg249}
   */
  function contactUsInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(assertContactMethod(properties.contactMethod));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Contact Us Interaction", Object.keys(properties), [
        "page",
        "section",
        "contactMethod"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "xSYODg249", "f65080d6c83c7a18b49e55804164c05aff29fc13b38622531a63894022d02a3e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Contact Us Interaction", {
        "page": properties.page,
        "section": properties.section,
        "contact_method": properties.contactMethod,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("xSYODg249", "Contact Us Interaction", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        {id: "uU28EvNtO", name: "contact_method", value: properties.contactMethod},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Contact Us Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Contact Us Interaction", {
          "page": properties.page,
          "section": properties.section,
          "contact_method": properties.contactMethod,
          }, "xSYODg249", "f65080d6c83c7a18b49e55804164c05aff29fc13b38622531a63894022d02a3e");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Contact Us Interaction", {
        "page": properties.page,
        "section": properties.section,
        "contact_method": properties.contactMethod,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Signup Started: Event sent when a users starts typing in one of the input fields to create an account on the site
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/zuiOZIZkM8}
   */
  function signupStarted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Signup Started", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "zuiOZIZkM8", "9c36921fe695fcf29f42007cfa20d0e30613f4ffb9f32ef96bcd63ca41dcd042", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Signup Started", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("zuiOZIZkM8", "Signup Started", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Signup Started': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Signup Started", {
          "page": properties.page,
          "section": properties.section,
          }, "zuiOZIZkM8", "9c36921fe695fcf29f42007cfa20d0e30613f4ffb9f32ef96bcd63ca41dcd042");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Signup Started", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Signup Completed: Event sent when a users successfully creates an account on the website
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   * @param {string} properties.userId_ - The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/HqxeJF-wyo}
   */
  function signupCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(assertUserId_(properties.userId_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Signup Completed", Object.keys(properties), [
        "page",
        "section",
        "userId_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "HqxeJF-wyo", "097f1370df31f5bac156ae8fde97f2dc8362393c8cf89d24e0632c05d89df379", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Signup Completed", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("HqxeJF-wyo", "Signup Completed", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Signup Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Signup Completed", {
          "page": properties.page,
          "section": properties.section,
          }, "HqxeJF-wyo", "097f1370df31f5bac156ae8fde97f2dc8362393c8cf89d24e0632c05d89df379");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.identify(properties.userId_, {});
      SegmentZeroStorefrontLanding.logEvent("Signup Completed", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Intercom Chat Initiated: Event sent when user clicks and initiates the Intercom Chat window
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/wem4ldTN68}
   */
  function intercomChatInitiated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Intercom Chat Initiated", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "wem4ldTN68", "eef4cddd566526602a6dbfa2016c458a59d82a1f8236b6c3e0c74aa8cc78a55c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Intercom Chat Initiated", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("wem4ldTN68", "Intercom Chat Initiated", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Intercom Chat Initiated': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Intercom Chat Initiated", {
          "page": properties.page,
          "section": properties.section,
          }, "wem4ldTN68", "eef4cddd566526602a6dbfa2016c458a59d82a1f8236b6c3e0c74aa8cc78a55c");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Intercom Chat Initiated", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Intercom Chat Abandoned: Event sent when user dismisses the Intercom Chat window
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/rYHQ6njeIn}
   */
  function intercomChatAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Intercom Chat Abandoned", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "rYHQ6njeIn", "f7782854ebb1d13e42d234625c756ab6b53144bf2124df23d75f47a89e99555e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Intercom Chat Abandoned", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("rYHQ6njeIn", "Intercom Chat Abandoned", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Intercom Chat Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Intercom Chat Abandoned", {
          "page": properties.page,
          "section": properties.section,
          }, "rYHQ6njeIn", "f7782854ebb1d13e42d234625c756ab6b53144bf2124df23d75f47a89e99555e");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Intercom Chat Abandoned", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Page Viewed: Special page view event sent when there is a page/screen navigation in the app
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/DbPvdYtO8s}
   */
  function pageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Page Viewed", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "DbPvdYtO8s", "61ed75fd23ff75085272f7bb82be33024b23d649e6e8903ffb523d9ea3b51177", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Page Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("DbPvdYtO8s", "Page Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.page(properties.segmentPageName_, {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Home Page Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/xNjHp5exfF}
   */
  function homePageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Home Page Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "xNjHp5exfF", "af4d6542c67a16f26fed6685d15dcdd22c16de544abaaed60534b170472b4fce", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Home Page Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("xNjHp5exfF", "Home Page Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Home Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Home Page Viewed", {}, "xNjHp5exfF", "af4d6542c67a16f26fed6685d15dcdd22c16de544abaaed60534b170472b4fce");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Home Page Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * About Us Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/A8J6oUw7zZ}
   */
  function aboutUsInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("About Us Interaction", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "A8J6oUw7zZ", "5b932d88fdc2889b306b62c1f26704fbd1d4c1d0e39de1d876c4d582676f2a6e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("About Us Interaction", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("A8J6oUw7zZ", "About Us Interaction", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'About Us Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("About Us Interaction", {
          "page": properties.page,
          "section": properties.section,
          }, "A8J6oUw7zZ", "5b932d88fdc2889b306b62c1f26704fbd1d4c1d0e39de1d876c4d582676f2a6e");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("About Us Interaction", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * CTA Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   * @param {string} properties.ctaLabel - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/W9eSTmQklm}
   */
  function ctaInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(assertCtaLabel(properties.ctaLabel));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CTA Interaction", Object.keys(properties), [
        "page",
        "section",
        "ctaLabel"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "W9eSTmQklm", "a100e02671454943f25b4d0ca108b1d85510f7d8c1c5c19ecab4d402f54eee44", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("CTA Interaction", {
        "page": properties.page,
        "section": properties.section,
        "cta_label": properties.ctaLabel,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("W9eSTmQklm", "CTA Interaction", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        {id: "U3HfUq-KRk", name: "cta_label", value: properties.ctaLabel},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'CTA Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CTA Interaction", {
          "page": properties.page,
          "section": properties.section,
          "cta_label": properties.ctaLabel,
          }, "W9eSTmQklm", "a100e02671454943f25b4d0ca108b1d85510f7d8c1c5c19ecab4d402f54eee44");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("CTA Interaction", {
        "page": properties.page,
        "section": properties.section,
        "cta_label": properties.ctaLabel,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Home Page Content Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/ivHjup1ZPc}
   */
  function homePageContentViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Home Page Content Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "ivHjup1ZPc", "d4891c975bc4928c23562c4e224b73a41637b721c0382fff8aa61a2819901fb6", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Home Page Content Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("ivHjup1ZPc", "Home Page Content Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Home Page Content Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Home Page Content Viewed", {}, "ivHjup1ZPc", "d4891c975bc4928c23562c4e224b73a41637b721c0382fff8aa61a2819901fb6");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Home Page Content Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Voice Product Page Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/2INM7d7JLW}
   */
  function voiceProductPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Voice Product Page Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "2INM7d7JLW", "6caa401ff1caa563524dabf520e868423cfd2be01267e84302f1fd79f0d29fa1", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Voice Product Page Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("2INM7d7JLW", "Voice Product Page Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Voice Product Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Voice Product Page Viewed", {}, "2INM7d7JLW", "6caa401ff1caa563524dabf520e868423cfd2be01267e84302f1fd79f0d29fa1");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Voice Product Page Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Voice Product Page Content Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/ZWXmLAnBrN}
   */
  function voiceProductPageContentViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Voice Product Page Content Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "ZWXmLAnBrN", "344078fe0d1c0c030d2862461b96994e8295b11871d518cf8a8a6fa0c0aef771", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Voice Product Page Content Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("ZWXmLAnBrN", "Voice Product Page Content Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Voice Product Page Content Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Voice Product Page Content Viewed", {}, "ZWXmLAnBrN", "344078fe0d1c0c030d2862461b96994e8295b11871d518cf8a8a6fa0c0aef771");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Voice Product Page Content Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Reviews Product Page Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/AgJNGiFqD7}
   */
  function reviewsProductPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Reviews Product Page Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "AgJNGiFqD7", "43d85617be423a83f539a88fbe6b9063633ca24dfe750619bbc593f3d580df68", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Reviews Product Page Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("AgJNGiFqD7", "Reviews Product Page Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Reviews Product Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Reviews Product Page Viewed", {}, "AgJNGiFqD7", "43d85617be423a83f539a88fbe6b9063633ca24dfe750619bbc593f3d580df68");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Reviews Product Page Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Reviews Product Page Content Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/vxlDbwfZa-}
   */
  function reviewsProductPageContentViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Reviews Product Page Content Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "vxlDbwfZa-", "acd604d71f424ba78132ac60d389771f562e8735b3e3804b13372520ca4d2b20", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Reviews Product Page Content Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("vxlDbwfZa-", "Reviews Product Page Content Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Reviews Product Page Content Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Reviews Product Page Content Viewed", {}, "vxlDbwfZa-", "acd604d71f424ba78132ac60d389771f562e8735b3e3804b13372520ca4d2b20");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Reviews Product Page Content Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Privacy Policy Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/56MSB6IpsC}
   */
  function privacyPolicyViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Privacy Policy Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "56MSB6IpsC", "c8b0b6bc451bf3a840b1e242ba766b4fd5bc2ece960a53612677c11c7bd94499", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Privacy Policy Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("56MSB6IpsC", "Privacy Policy Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Privacy Policy Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Privacy Policy Viewed", {}, "56MSB6IpsC", "c8b0b6bc451bf3a840b1e242ba766b4fd5bc2ece960a53612677c11c7bd94499");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Privacy Policy Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Terms of Service Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/Px8BRin6Na}
   */
  function termsOfServiceViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Terms of Service Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Px8BRin6Na", "fa4c911f174f8cd913661917d84c19ee3804fccb73ea22e45aeb76fcf7e7acb7", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Terms of Service Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Px8BRin6Na", "Terms of Service Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Terms of Service Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Terms of Service Viewed", {}, "Px8BRin6Na", "fa4c911f174f8cd913661917d84c19ee3804fccb73ea22e45aeb76fcf7e7acb7");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Terms of Service Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * About Us Viewed: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/wIAbpMtmH4}
   */
  function aboutUsViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("About Us Viewed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "wIAbpMtmH4", "3ad9a248cc962ebbde3320925fc14629f0b0d8b8dac95aa5c92b19df88b0dec1", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("About Us Viewed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("wIAbpMtmH4", "About Us Viewed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'About Us Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("About Us Viewed", {}, "wIAbpMtmH4", "3ad9a248cc962ebbde3320925fc14629f0b0d8b8dac95aa5c92b19df88b0dec1");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("About Us Viewed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Application Dropdown Interaction: No description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/GeZI5cTa2W}
   */
  function applicationDropdownInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Application Dropdown Interaction", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "GeZI5cTa2W", "2290b484d4c4d1b09f6d80733edfb60cae7749ca2171956a692b9ae954229644", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Application Dropdown Interaction", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("GeZI5cTa2W", "Application Dropdown Interaction", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Application Dropdown Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Application Dropdown Interaction", {}, "GeZI5cTa2W", "2290b484d4c4d1b09f6d80733edfb60cae7749ca2171956a692b9ae954229644");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Application Dropdown Interaction", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Monthly Order Count Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {int} properties.orderCount - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/yKzHv5SMgZ}
   */
  function monthlyOrderCountInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalOrderCount(properties.orderCount));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Monthly Order Count Interaction", Object.keys(properties), [
        "orderCount"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "yKzHv5SMgZ", "8d7422ce216d468bdffa0ab7e0b54f4cd905df3e3723064c3708569f66491916", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Monthly Order Count Interaction", {
        "order_count": properties.orderCount,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("yKzHv5SMgZ", "Monthly Order Count Interaction", messages, [
        {id: "ciO9jwO5We", name: "order_count", value: properties.orderCount},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Monthly Order Count Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Monthly Order Count Interaction", {
          "order_count": properties.orderCount,
          }, "yKzHv5SMgZ", "8d7422ce216d468bdffa0ab7e0b54f4cd905df3e3723064c3708569f66491916");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Monthly Order Count Interaction", {
        "order_count": properties.orderCount,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Application Selected: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.applicationName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/7N5qtSSEhv}
   */
  function applicationSelected(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalApplicationName(properties.applicationName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Application Selected", Object.keys(properties), [
        "applicationName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "7N5qtSSEhv", "9e685e8eca8a21f90b3eb528f581e0e74d03afa5105ea6abe33a9e6e83b1ce91", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Application Selected", {
        "application_name": properties.applicationName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("7N5qtSSEhv", "Application Selected", messages, [
        {id: "Xu46AHz9Z5", name: "application_name", value: properties.applicationName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Application Selected': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Application Selected", {
          "application_name": properties.applicationName,
          }, "7N5qtSSEhv", "9e685e8eca8a21f90b3eb528f581e0e74d03afa5105ea6abe33a9e6e83b1ce91");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Application Selected", {
        "application_name": properties.applicationName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Average Order Value Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {float} properties.avgOrderValue - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/ALQ7bY2Pgm}
   */
  function averageOrderValueInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalAvgOrderValue(properties.avgOrderValue));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Average Order Value Interaction", Object.keys(properties), [
        "avgOrderValue"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "ALQ7bY2Pgm", "650674c86548eb331ce8abd1a0054fcdb580a7b1fdabc5bec8b1d2ff4517ec24", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Average Order Value Interaction", {
        "avg_order_value": properties.avgOrderValue,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("ALQ7bY2Pgm", "Average Order Value Interaction", messages, [
        {id: "VKVSDe6-gI", name: "avg_order_value", value: properties.avgOrderValue},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Average Order Value Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Average Order Value Interaction", {
          "avg_order_value": properties.avgOrderValue,
          }, "ALQ7bY2Pgm", "650674c86548eb331ce8abd1a0054fcdb580a7b1fdabc5bec8b1d2ff4517ec24");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Average Order Value Interaction", {
        "avg_order_value": properties.avgOrderValue,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * ROI CTA Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   * @param {float} properties.avgOrderValue - no description
   * @param {string} properties.ctaLabel - no description
   * @param {float} properties.revenueAmount - no description
   * @param {int} properties.customerVisitCount - no description
   * @param {int} properties.customerListCount - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/icfqc5AM3H}
   */
  function roiCtaInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(assertOptionalAvgOrderValue(properties.avgOrderValue));
      messages = messages.concat(assertCtaLabel(properties.ctaLabel));
      messages = messages.concat(assertRevenueAmount(properties.revenueAmount));
      messages = messages.concat(assertCustomerVisitCount(properties.customerVisitCount));
      messages = messages.concat(assertCustomerListCount(properties.customerListCount));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ROI CTA Interaction", Object.keys(properties), [
        "page",
        "section",
        "avgOrderValue",
        "ctaLabel",
        "revenueAmount",
        "customerVisitCount",
        "customerListCount"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "icfqc5AM3H", "3534d3a835897db7acb23f9f1ccf10d358224d86424b8b36e13776bf793b9dfa", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("ROI CTA Interaction", {
        "page": properties.page,
        "section": properties.section,
        "avg_order_value": properties.avgOrderValue,
        "cta_label": properties.ctaLabel,
        "revenue_amount": properties.revenueAmount,
        "customer_visit_count": properties.customerVisitCount,
        "customer_list_count": properties.customerListCount,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("icfqc5AM3H", "ROI CTA Interaction", messages, [
        {id: "0B4bqaXJLE", name: "customer_visit_count", value: properties.customerVisitCount},
        {id: "0MvJ39b8tR", name: "customer_list_count", value: properties.customerListCount},
        {id: "ITCVfkSTke", name: "revenue_amount", value: properties.revenueAmount},
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        {id: "U3HfUq-KRk", name: "cta_label", value: properties.ctaLabel},
        {id: "VKVSDe6-gI", name: "avg_order_value", value: properties.avgOrderValue},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'ROI CTA Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ROI CTA Interaction", {
          "page": properties.page,
          "section": properties.section,
          "avg_order_value": properties.avgOrderValue,
          "cta_label": properties.ctaLabel,
          "revenue_amount": properties.revenueAmount,
          "customer_visit_count": properties.customerVisitCount,
          "customer_list_count": properties.customerListCount,
          }, "icfqc5AM3H", "3534d3a835897db7acb23f9f1ccf10d358224d86424b8b36e13776bf793b9dfa");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("ROI CTA Interaction", {
        "page": properties.page,
        "section": properties.section,
        "avg_order_value": properties.avgOrderValue,
        "cta_label": properties.ctaLabel,
        "revenue_amount": properties.revenueAmount,
        "customer_visit_count": properties.customerVisitCount,
        "customer_list_count": properties.customerListCount,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Monthly Visit Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {int} properties.monthlyVisit - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/2vjuSdUMxx}
   */
  function monthlyVisitInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertMonthlyVisit(properties.monthlyVisit));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Monthly Visit Interaction", Object.keys(properties), [
        "monthlyVisit"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "2vjuSdUMxx", "1d2f95501b165edd803709afde24268c2bdf2543542acdcc9333e9f0bd495e8c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Monthly Visit Interaction", {
        "monthly_visit": properties.monthlyVisit,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("2vjuSdUMxx", "Monthly Visit Interaction", messages, [
        {id: "pHKmcYYhSw", name: "monthly_visit", value: properties.monthlyVisit},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Monthly Visit Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Monthly Visit Interaction", {
          "monthly_visit": properties.monthlyVisit,
          }, "2vjuSdUMxx", "1d2f95501b165edd803709afde24268c2bdf2543542acdcc9333e9f0bd495e8c");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Monthly Visit Interaction", {
        "monthly_visit": properties.monthlyVisit,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Instagram Follower Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {int} properties.followers - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/U05JVvouXZ}
   */
  function instagramFollowerInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertFollowers(properties.followers));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Instagram Follower Interaction", Object.keys(properties), [
        "followers"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "U05JVvouXZ", "f291bdafaaca808ed52eeef100d4b4490be17802b7339fdf135bff0d4fd704eb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Instagram Follower Interaction", {
        "followers": properties.followers,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("U05JVvouXZ", "Instagram Follower Interaction", messages, [
        {id: "AqAMgc6zef", name: "followers", value: properties.followers},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Instagram Follower Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Instagram Follower Interaction", {
          "followers": properties.followers,
          }, "U05JVvouXZ", "f291bdafaaca808ed52eeef100d4b4490be17802b7339fdf135bff0d4fd704eb");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Instagram Follower Interaction", {
        "followers": properties.followers,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Existing Email List Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {int} properties.emailListSize - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/X19Utgi_sD}
   */
  function existingEmailListInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertEmailListSize(properties.emailListSize));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Existing Email List Interaction", Object.keys(properties), [
        "emailListSize"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "X19Utgi_sD", "6febed4b7adeb6344303dcbc7615efb162ca207c800a8a79d2db6515bcb67921", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Existing Email List Interaction", {
        "email_list_size": properties.emailListSize,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("X19Utgi_sD", "Existing Email List Interaction", messages, [
        {id: "GilQbaWJIt", name: "email_list_size", value: properties.emailListSize},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Existing Email List Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Existing Email List Interaction", {
          "email_list_size": properties.emailListSize,
          }, "X19Utgi_sD", "6febed4b7adeb6344303dcbc7615efb162ca207c800a8a79d2db6515bcb67921");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Existing Email List Interaction", {
        "email_list_size": properties.emailListSize,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Navigation Menu Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.navSectionName - no description
   * @param {string} properties.navItemName - no description
   * @param {string} properties.navItemUrl - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/XMW-NuWDbB}
   */
  function navigationMenuInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertNavSectionName(properties.navSectionName));
      messages = messages.concat(assertNavItemName(properties.navItemName));
      messages = messages.concat(assertNavItemUrl(properties.navItemUrl));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Navigation Menu Interaction", Object.keys(properties), [
        "navSectionName",
        "navItemName",
        "navItemUrl"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "XMW-NuWDbB", "58ffeb345c35a4da7a4fe266ac8494fb03c7b9f2d4562d98be61953accefe288", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Navigation Menu Interaction", {
        "nav_section_name": properties.navSectionName,
        "nav_item_name": properties.navItemName,
        "nav_item_url": properties.navItemUrl,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("XMW-NuWDbB", "Navigation Menu Interaction", messages, [
        {id: "_5lOiKrRRT", name: "nav_section_name", value: properties.navSectionName},
        {id: "gRcqidYwzj", name: "nav_item_url", value: properties.navItemUrl},
        {id: "xWRKGkqCu", name: "nav_item_name", value: properties.navItemName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Navigation Menu Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Navigation Menu Interaction", {
          "nav_section_name": properties.navSectionName,
          "nav_item_name": properties.navItemName,
          "nav_item_url": properties.navItemUrl,
          }, "XMW-NuWDbB", "58ffeb345c35a4da7a4fe266ac8494fb03c7b9f2d4562d98be61953accefe288");
      }
      // destination SegmentZeroStorefrontLanding
      SegmentZeroStorefrontLanding.logEvent("Navigation Menu Interaction", {
        "nav_section_name": properties.navSectionName,
        "nav_item_name": properties.navItemName,
        "nav_item_url": properties.navItemUrl,
        }, {});
    } else {
      // do nothing
    }
  }

  exports.ContactMethod = ContactMethod;
  exports.setAvoLogger = setAvoLogger;
  exports.contactUsInteraction = contactUsInteraction;
  exports.signupStarted = signupStarted;
  exports.signupCompleted = signupCompleted;
  exports.intercomChatInitiated = intercomChatInitiated;
  exports.intercomChatAbandoned = intercomChatAbandoned;
  exports.pageViewed = pageViewed;
  exports.homePageViewed = homePageViewed;
  exports.aboutUsInteraction = aboutUsInteraction;
  exports.ctaInteraction = ctaInteraction;
  exports.homePageContentViewed = homePageContentViewed;
  exports.voiceProductPageViewed = voiceProductPageViewed;
  exports.voiceProductPageContentViewed = voiceProductPageContentViewed;
  exports.reviewsProductPageViewed = reviewsProductPageViewed;
  exports.reviewsProductPageContentViewed = reviewsProductPageContentViewed;
  exports.privacyPolicyViewed = privacyPolicyViewed;
  exports.termsOfServiceViewed = termsOfServiceViewed;
  exports.aboutUsViewed = aboutUsViewed;
  exports.applicationDropdownInteraction = applicationDropdownInteraction;
  exports.monthlyOrderCountInteraction = monthlyOrderCountInteraction;
  exports.applicationSelected = applicationSelected;
  exports.averageOrderValueInteraction = averageOrderValueInteraction;
  exports.roiCtaInteraction = roiCtaInteraction;
  exports.monthlyVisitInteraction = monthlyVisitInteraction;
  exports.instagramFollowerInteraction = instagramFollowerInteraction;
  exports.existingEmailListInteraction = existingEmailListInteraction;
  exports.navigationMenuInteraction = navigationMenuInteraction;
  exports.setup_ = setup_;
  exports.initAvo = initAvo;
  exports.avoInspectorApiKey = avoInspectorApiKey;
}(typeof exports === 'undefined' ? this.Avo = {} : exports));

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["contactUsInteraction","signupStarted","signupCompleted","intercomChatInitiated","intercomChatAbandoned","pageViewed","homePageViewed","aboutUsInteraction","ctaInteraction","homePageContentViewed","voiceProductPageViewed","voiceProductPageContentViewed","reviewsProductPageViewed","reviewsProductPageContentViewed","privacyPolicyViewed","termsOfServiceViewed","aboutUsViewed","applicationDropdownInteraction","monthlyOrderCountInteraction","applicationSelected","averageOrderValueInteraction","roiCtaInteraction","monthlyVisitInteraction","instagramFollowerInteraction","existingEmailListInteraction","navigationMenuInteraction"]
