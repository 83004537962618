import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export const types = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

const Button = props => {
  const { type = types.PRIMARY, text = '', className = '' } = props

  const handleClick = e => {
    if (props.onClick) {
      props.onClick(e)
    }
  }

  return (
    <button className={ `eg-button ${type} ${className}` } onClick={ handleClick }>
      <span className='label'>{ text }</span>
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(types)),
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default Button
