import React from 'react'
import PropTypes from 'prop-types'

import { Slider, Input } from '../index'

import './styles.scss'

const SliderInput = props => {
  const {
    name,
    value,
    min = 0,
    step = 1,
    max,
    className = '',
    onChange
  } = props

  const handleChange = (value) => {
    if (onChange && !isNaN(value)) {
      onChange(value)
    }
  }

  return (
    <div className={ `eg-input-slider ${className}` }>
      <Input
        className='input'
        name={ name }
        value={ `${value}` }
        onChange={ (e) => handleChange(e.target.value) }
      />
      <Slider
        className='slider'
        min= { min }
        max={ max }
        step={step}
        value={ value }
        onChange={ handleChange }
      />
    </div>
  )
}

SliderInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func
}

export default SliderInput
