import Avo from '../Avo'

export const analyticEvents = {
  PAGE_VIEWED: 'pageViewed',
  HOMEPAGE_VIEWED: 'homePageViewed',
  INTERCOM_CHAT_INITIATED: 'intercomChatInitiated',
  INTERCOM_CHAT_ABANDONED: 'intercomChatAbandoned',
  ABOUT_US_INTERACTION: 'aboutUsInteraction',
  CTA_INTERACTION: 'ctaInteraction',
  CONTACT_US_INTERACTION: 'contactUsInteraction',
  SIGNUP_STARTED: 'signupStarted',
  SIGNUP_COMPLETED: 'signupCompleted',
  HOMEPAGE_CONTENT_VIEWED: 'homePageContentViewed',

  VOICE_PAGE_VIEWED: 'voiceProductPageViewed',
  VOICE_PAGE_CONTENT_VIEWED: 'voiceProductPageContentViewed',
  REVIEWS_PAGE_VIEWED: 'reviewsProductPageViewed',
  REVIEWS_PAGE_CONTENT_VIEWED: 'reviewsProductPageContentViewed',
  PRIVACY_POLICY_VIEWED: 'privacyPolicyViewed',
  TERMS_OF_SERVICE_VIEWED: 'termsOfServiceViewed',
  ABOUT_US_VIEWED: 'aboutUsViewed',

  // calculator events

  MONTHLY_VISIT_INTERACTION: 'monthlyVisitInteraction',
  AVERAGE_ORDER_VALUE_INTERACTION: 'averageOrderValueInteraction',
  INSTAGRAM_FOLLOWER_INTERACTION: 'instagramFollowerInteraction',
  EXISTING_EMAIL_LIST_INTERACTION: 'existingEmailListInteraction',
  ROI_CTA_INTERACTION: 'roiCtaInteraction',
  NAVIGATION_MENU_INTERACTION: 'navigationMenuInteraction'
}

class EGAnalytic {
  constructor() {
    this.Avo = Avo

    if (typeof window !== 'undefined') {
      if (process.env.GATSBY_AVO_ENV === 'production') {
        this.Avo.initAvo({ env: 'prod' })
      } else {
        this.Avo.initAvo({ env: 'dev' })
      }
    }
  }

  track(key, opts) {
    try {
      this.Avo[key](opts)
    } catch (e) {
      console.error('Error while tracking event ->', key, opts, e)
    }
  }
}

export const EGAnalytics = new EGAnalytic()
